import "@fontsource/poppins/300.css" // light
import "@fontsource/poppins/400.css" // normal
import "@fontsource/poppins/600.css" // semibold
import "@fontsource/poppins/700.css" // bold
// import "@fontsource/poppins/800.css" // extrabold
import "@fontsource/poppins/900.css" // black
import { addCss, addClass, configure } from '../lib/ReactUtils'

configure({
  colors: {
    primary: '009cfd',
    'primary-dark': '2b6cb0',
    highlight: 'ed8936'
  }
})

// scrollbar taken from here: https://stackoverflow.com/questions/66286943/how-to-make-vertical-scrollbar-thinner-and-get-rid-of-the-right-bottom-empty-spa

addCss(`
  a {
    @apply transition duration-200 cursor-pointer focus:outline-none
  }
  a:not(.basic):not(.dark), .link {
    @apply text-primary
  }
  a:not(.basic):not(.dark):hover, .link:hover {
    @apply text-primary-dark
  }

  :root {
    --scroll-bar-color: #c5c5c5;
    --scroll-bar-bg-color: #f6f6f6;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
    z-index: 100;
  }

  *::-webkit-scrollbar-track {
    background: var(--scroll-bar-bg-color);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--scroll-bar-color);
    border-radius: 20px;
    border: 3px solid var(--scroll-bar-bg-color);
  }

`)

addClass('quote', 'py-4 pl-3 pr-2 border-l-4 border-primary bg-blue-100 my-5 text-primary-dark font-semibold')
