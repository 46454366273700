import { useLayoutEffect, useRef, useEffect } from 'react'
import { processClasses } from '/src/vendors/runcss'

export { addCss, addClass, processClasses, configure } from '/src/vendors/runcss'


export const useClasses = () => {
  const ref = useRef()
  useLayoutEffect(() => {
    processRef(ref)
  })
  return ref
}

export const processRef = (ref) => {
  const parent = typeof ref == 'string' ? document.querySelector(ref) : ref.current
  if (parent) {
    if (parent.className) processClasses(parent.className)
    parent.querySelectorAll('*[class]').forEach(function(element) {
      if (element.className) processClasses(element.className)
    })
  }
}

export function useOnClickAway(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) return
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    [ref, handler]
  )
}
